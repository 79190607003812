<template>
  <div>
    <portal to="body-top">
      <customer-name title=""/>
    </portal>
    <h1 class="text-center text-danger">
      To Do
    </h1>
  </div>
</template>

<script>

import CustomerName from '../../components/CustomerName'

export default {
  name: 'SalesByProduct',
  components: {
    CustomerName
  },
  created() {
    this.$emit('updateMenu', 'home-customers-sls-by-product')
  },
}
</script>

<style scoped>

</style>
